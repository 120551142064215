interface IDialogError {
  title?: string
  subtitle?: string
}

export function useErrorDialog() {
  const errorData = useState<IDialogError | null>('currentError', () => null);
  const isErrorDialog = useState('isErrorDialog', () => false);

  function showErrorDialog(e: IDialogError) {
    if (!e) return;

    errorData.value = e;
    isErrorDialog.value = true;
  }

  return {
    errorData,
    isErrorDialog,
    showErrorDialog,
  };
}
